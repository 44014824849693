import React, { useState, useEffect, useRef } from "react";
import NProgress from "nprogress";
import "lazysizes";
import { useRouter } from 'next/router'
import { ThemeProvider } from "@emotion/react";
import { IntlProvider, addLocaleData } from "react-intl";
import theme from "@/styles";
import localeDE from "react-intl/locale-data/de";
import localeEN from "react-intl/locale-data/en";
import customLocaleDE from "@/i18n/de-DE.json";
import customLocaleEN from "@/i18n/en-EN.json";
import GlobalStyles from "@/components/GlobalStyles/GlobalStyles";
import * as Sentry from '@sentry/node';
import { Integrations as TracingIntegrations } from "@sentry/tracing";
import { stage } from '@/config';
import "swiper/css/swiper.min.css";
import "../components/GlobalStyles/vendors/bootstrap-grid.min.css";
import Head from "next/head";

// add general locale data to react intl provider
addLocaleData([...localeDE, ...localeEN]);

// define custom messages
const messages = {
  de: customLocaleDE,
  en: customLocaleEN
};


Sentry.init({
  dsn: 'https://edb959395425431da56009e5fe718a3b@sentry.brandung-dev.de/4',
  enabled: process.env.NODE_ENV === 'production',
  environment: stage ? 'staging' : 'production',
  integrations: [new TracingIntegrations.BrowserTracing()],
  tracesSampleRate: 0.2,
  ignoreErrors: [
    'ResizeObserver loop limit exceeded',
    'fbq is not defined',
    'Object doesn\'t support property or method \'prepend\'',
    'Loading chunk',
  ],
});


const Brandung = ({ Component, pageProps }) => {
  const router = useRouter();
  const [language, setLanguage] = useState("de");

  useEffect(() => {
    let lang = "";
    const defaultLocale = "de";
    let navigatorLanguage = navigator.language.split(/[-_]/)[0];
    // set language to default locale when we do not offer a locale for the navigator language
    let currentLanguage =
      navigatorLanguage === "de" || navigatorLanguage === "en"
        ? navigatorLanguage
        : defaultLocale;

    Sentry.setTag("page_locale", currentLanguage);

    const languageRegex = /^\/(en|it|cz|hu)/;
    const langString = languageRegex.exec(window.location.pathname);

    if (langString) {
      lang = langString[1];
    }

    // register service worker
    if ("serviceWorker" in navigator) {
      window.addEventListener("load", () => {
        navigator.serviceWorker
          .register("/sw.js")
          .then(registration => {
            // console.log("SW registered: ", registration);
          })
          .catch(registrationError => {
            // console.log("SW registration failed: ", registrationError);
          });
      });
    }

    router.events.on("routeChangeStart", () => NProgress.start());
    // 'routeChangeComplete' is bound in './components/Header
    router.events.on("routeChangeError", () => NProgress.done());

    setLanguage(lang ? lang : currentLanguage);
    // console.log("LangState", this.state.language);
  });



  return (
    <>
      <Head>
        <meta name="viewport" content="width=device-width, initial-scale=1" />
      </Head>

      <IntlProvider
        locale={language}
        messages={messages[language]}
      >
        <ThemeProvider theme={theme}>
          <>
            <GlobalStyles />
            <Component {...pageProps} />
          </>
        </ThemeProvider>
      </IntlProvider>
    </>

  );
}

export default Brandung;
